.text {

    padding-top: 4px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-family: 'proto', sans-serif;
    word-spacing: -0.1rem;

    @include sizethree {

        padding-top: 55px;

    }

    @include sizeone {

        word-spacing: -0.08rem;

    }

    p {

        min-height: 20px;

    }

    u {

        text-decoration: none;
        text-indent: 0px;

    }

    a {

        color: $red !important;
        text-decoration: underline !important;

        &:hover {

            text-decoration: none !important;

        }

    }

    strong {

        font-size: 0.75rem;
        letter-spacing: 0.8px;

    }

    em {

        font-style: italic;

    }

    &_item {

        width: 650px;
        padding-bottom: 150px;

        transition: height 0.3s ease,
            opacity 0.3s ease;

        &.hide {

            height: 0px;
            opacity: 0;
            padding: 0px;
            overflow: hidden;
            pointer-events: none;

        }

        @include sizeone {

            width: calc(100% - 30px);

        }

        .titrage {

            text-align: center;
            padding-bottom: 50px;

        }

        .text_lang {

            display: none;

            text-indent: 2rem;

            p:first-child {

                text-indent: 0rem;

            }

        }

        .credits p {

            padding-bottom: 24px;
            font-size: 0.78rem;
            letter-spacing: 0.4px;
            word-spacing: -0.05em;

            @include sizeone {

                font-size: 0.83rem;
                letter-spacing: 0.45px;

            }

        }

        .credits p,
        .text_lang p {

            text-align: justify;
            text-justify: auto;
            hyphens: auto;

            &.centered {

                text-align: center;
                text-indent: 0px;

            }

        }

    }

}

[data-lang='is-fr'] .text_fr {

    display: block;

}

[data-lang='is-en'] .text_en {

    display: block;

}