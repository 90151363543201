/* ------------------------------------------------------------------ */
/*        HOME
/* ------------------------------------------------------------------ */

.home {

    margin: 0px 9px;
    padding-top: $header_marging;
    padding-bottom: 24px;

    @include sizethree {

        padding-top: 30px;
        margin: 0px 7px;

    }

    .gallery {

        display: flex;
        flex-wrap: wrap;
        opacity: 0;

        transition: opacity 0.3s ease;

        &_item {

            width: calc((100vw - 18px) / 8);
            height: calc((100vw - 18px) / 8);
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'proto';
            word-spacing: -0.1rem;
            opacity: 0;

            transition: opacity 0.3s ease;

            @include sizethree {

                width: calc((100vw - 18px) / 5);
                height: calc((100vw - 18px) / 5);

            }

            @include sizeone {

                width: calc((100vw - 14px) / 3);
                height: calc((100vw - 14px) / 3);
                word-spacing: -0.08rem;

            }

            &.hide {

                opacity: 0 !important;
                pointer-events: none;

            }

            .top {

                position: absolute;
                width: calc(((var(--tw) * ((100vw - 18px) / 8)) / var(--th)) - 20px);
                height: calc(((100vw - 18px) / 8) - 20px);
                z-index: 100;
                cursor: pointer;

                @include sizethree {

                    width: calc(((var(--tw) * ((100vw - 18px) / 5)) / var(--th)) - 20px);
                    height: calc((((100vw - 18px) / 5)) - 20px);

                }
            }

            .big:not(.active) {
                display: none;
            }

            .image_wrapper {

                // height: calc(((100vw - 18px) / 8) - 9px);
                // width: calc(((100vw - 18px) / 8) - 9px);
                // margin: 10px;
                // cursor: zoom-in;

                // @include sizethree {

                //     height: calc(((100vw - 18px) / 5) - 9px);
                //     width: calc(((100vw - 18px) / 5) - 9px);

                // }

                // @include sizeone {

                //     width: calc(((100vw - 20px) / 3) - 12px);
                //     height: calc(((100vw - 20px) / 3) - 12px);
                //     margin: 0px;

                // }

                img {

                    object-fit: contain;
                    width: calc(((100vw - 18px) / 8) - 18px);
                    height: calc(((100vw - 18px) / 8) - 18px);
                    object-position: left;
                    cursor: pointer;

                    transition: filter 0.3s ease;

                    @include sizethree {

                        height: calc(((100vw - 18px) / 5) - 18px);
                        width: calc(((100vw - 18px) / 5) - 18px);
                        // -webkit-filter: grayscale(0%);
                        // filter: grayscale(0%);

                    }

                    @include sizeone {

                        width: calc(((100vw - 20px) / 3) - 12px);
                        height: calc(((100vw - 20px) / 3) - 12px);
                        // -webkit-filter: grayscale(0%);
                        // filter: grayscale(0%);

                    }

                }

            }

            &.horizontal {

                width: calc(((100vw - 18px) / 8) * 2);
                height: calc((100vw - 18px) / 8);
                justify-content: flex-start;
                position: relative;
                left: 2px;

                @include sizethree {

                    width: calc(((100vw - 18px) / 5) * 2);
                    height: calc(((100vw - 18px) / 5));

                }

                @include sizeone {

                    width: calc(((100vw - 20px) / 3) * 2);
                    height: calc(((100vw - 20px) / 3));

                }

                .image_wrapper {

                    .top {

                        position: absolute;
                        width: calc(((var(--tw) * (((100vw - 18px) / 8) * 2)) / var(--th)) - 20px);
                        height: calc(((100vw - 18px) / 8) - 20px);
                        z-index: 100;
                        cursor: pointer;

                        @include sizethree {

                            width: calc(((var(--tw) * ((100vw - 18px) / 5)) / var(--th)) - 20px);
                            height: calc(((100vw - 18px) / 5) - 20px);

                        }
                    }

                    img {

                        position: relative;
                        left: 7px;

                        width: calc((((100vw - 18px) / 8) - 18px) * 2);

                        @include sizethree {

                            width: calc((((100vw - 18px) / 5) - 18px) * 2);

                        }

                        @include sizeone {

                            width: calc((((100vw - 20px) / 3) - 25px) * 2);
                        }

                    }
                }

            }

        }

    }

    .arrow {

        display: block;
        position: fixed;
        bottom: 35px;
        left: 50%;
        transform: translate(-50%, 0%);
        opacity: 0;
        width: 50px;
        height: 40px;
        stroke: black;
        fill: transparent;
        stroke-width: 3px;


        @include sizethree {

            display: none;

        }

        &.hide {

            display: none;

        }

    }

    &.large .arrow {

        animation: 2s ease arrowanim 2s;
        opacity: 1;
        transition: opacity 0.3s ease 2s;

    }

    &.xlarge .arrow {

        opacity: 0;

    }

    @keyframes arrowanim {

        0% {
            bottom: 35px;
        }

        20% {
            bottom: 35px;
        }

        50% {
            bottom: 25px;
        }

        100% {
            bottom: 35px;
        }

    }

    &.large .gallery {

        flex-direction: column;
        background-color: white;
        scroll-snap-type: y mandatory;
        // scroll-padding: calc((76px + 23px + 100px) / 2);
        flex-wrap: nowrap;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100vw;

        @include sizethree {

            scroll-snap-type: unset;

        }

        .gallery_item[data-medium='date'] {
            display: none
        }

        .gallery_item {

            width: auto;
            height: 100%;
            padding: calc(199px / 2) 0px;
            cursor: zoom-out;
            display: flex;
            justify-content: center;
            scroll-snap-align: start;
            scroll-padding: calc(199px / 2);

            @include sizethree {

                height: auto;
                width: 100vw;
                padding: 0px;
                margin: 75px 0px;

            }

            .image_wrapper {

                max-width: calc(100vw - 40px);
                margin: 0px;
                cursor: zoom-in;

                width: calc((((var(--wh) * 100) - 199px) * var(--tw)) / var(--th));
                height: calc((var(--wh) * 100) - 199px);

                @include sizethree {

                    height: calc((100vw) * var(--th) / var(--tw));
                    width: 100vw;
                    max-width: 100vw;

                }

            }

            img {

                max-width: calc(100vw - 40px);
                // -webkit-filter: grayscale(0%);
                // filter: grayscale(0%);
                margin: 0px;
                cursor: zoom-in;
                object-fit: contain;
                object-position: center;

                width: 100%;
                height: 100%;

                @include sizethree {

                    height: calc(100vw * var(--th) / var(--tw));
                    width: 100vw;
                    max-width: 100vw;

                }

            }

            .small {

                display: none;

            }

            .big {

                display: block;
                filter: blur(10px);
                object-fit: unset;

                will-change: filter;
                transition: filter 0.3s ease;

                &.charged {

                    filter: blur(0px);

                }

            }

            &.horizontal,
            &.horizontal img {

                left: 0px;


            }

            &.hide {

                opacity: 0;
                height: 0px;
                overflow: hidden;
                padding: 0px;
                margin: 0px;

            }

        }

        figure:first-of-type {

            @include sizethree {

                margin-top: 0px;
            }

        }

    }

    &.xlarge .gallery {

        flex-direction: column;
        scroll-snap-type: none;

        .gallery_item[data-medium='date'] {
            display: none
        }

        .gallery_item {

            width: 100vw;
            height: fit-content;
            margin: 50px 0px;
            max-width: 100vw;

            img {

                width: 100vw;
                height: auto;
                margin: 2px 0px;
                max-width: 100vw;
                object-fit: unset;
                // -webkit-filter: grayscale(0%);
                // filter: grayscale(0%);
                cursor: zoom-out;

            }

            &.horizontal img {

                left: 0px;

            }

            .image_wrapper {

                height: fit-content;
                width: 100vw;
                max-width: 100vw;

            }

            &:nth-child(2) {

                margin-top: 0px;
                padding-top: 0px;

                .image_wrapper,
                img {

                    margin: 0px;

                }

            }

            &.hide {

                opacity: 0;
                height: 0px;
                overflow: hidden;

            }

        }

    }

    &.large,
    &.xlarge {

        padding-top: 0px;
        margin: 0px;

    }

}

body.body.large {

    height: 100vh;
    overflow: hidden;

}

.cross_container {

    display: none;
    cursor: pointer;
    z-index: 100000;

}

.cross {

    padding-bottom: 15px;
    padding-left: 15px;

    svg {

        width: 14.1px;
        height: 14.1px;

        line {

            stroke: black;

            @include sizethree {

                stroke: white;

            }

        }

    }

    transform-origin: right;
    will-change: transform;
    // transition: transform 0.3s ease;

}

// .cross_container:hover .cross {

//     transform: scaleX(0.4);

// }

.large .cross_container {

    display: block;
    position: fixed;
    top: 4px;
    right: 20px;
    width: 29px;

    @include sizeone {

        right: 15px;

    }

}

.xlarge {

    header {

        display: none;

    }

    .cross {

        display: none;

    }

    .footer {

        display: none;

    }

}

.large {

    .footer {

        @include sizethree {

            display: none;

        }

    }

}

.go .home .gallery {

    opacity: 1;

}