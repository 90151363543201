/* ------------------------------------------------------------------ */
/*        General HTML - BODY
/* ------------------------------------------------------------------ */
// * {
//     outline: none;
//     -ms-overflow-style: none;  // IE 10+
//     overflow: -moz-scrollbars-none;  // Firefox
//     &::-webkit-scrollbar {
//         display: none;
//     }
// }

/* ------------------------------------------------------------------ */
/*        HEADER
/* ------------------------------------------------------------------ */

$header_marging: 76px;

header {

    padding: 2px $marge_site;
    position: fixed;
    width: 100vw;
    background-color: white;
    height: $header_marging;
    z-index: 1000;

    transition: opacity 0.5s ease 1.5s,
        top 0.5s ease 1.5s;


    @include sizethree {

        height: 36px;

    }


    .nav {

        ul {

            padding-top: 2px;

            li {

                text-decoration: none;
                padding: 0px;
                width: fit-content;

                @include sizethree {

                    padding: 2px 7px;

                }

                a {

                    // transform: scaleX(1);
                    // transform-origin: left;
                    // display: block;
                    // transition: transform 0.3s ease;

                }

                a:hover {

                    // transform: scaleX(1.7);

                    font-family: 'proto', sans-serif;
                    word-spacing: -0.1em;

                    @include sizeone {

                        word-spacing: -0.08em;

                    }

                }

                &.selected {

                    color: $red;
                    font-family: 'proto', sans-serif;
                    word-spacing: -0.1em;

                    a:hover {

                        transform: scaleX(1);

                    }

                    @include sizeone {

                        word-spacing: -0.08em;

                    }

                }

            }

            @include sizethree {

                display: flex;
                justify-content: center;

            }
        }

    }

}

.hero {

    position: fixed;
    top: 4px;
    left: 50%;
    width: 500px;
    max-width: 70vw;
    transform: translate(-50%, 0%);
    text-align: center;
    z-index: 1000000;

    transition: opacity 0.3s ease,
        transform 0.3s ease;

    @include sizethree {

        width: calc(100% - 70px);

    }

    .ligne_one,
    .ligne_three {

        font-family: 'proto', sans-serif;
        word-spacing: -0.1em;

        @include sizeone {

            word-spacing: -0.08em;

        }

        .date {

            font-size: 0.8rem;

        }

    }

    &.transp {

        opacity: 0 !important;
        transition: none;

    }


    @include sizethree {

        display: none;

    }

}

.large,
.xlarge {

    header {

        background-color: transparent;

    }

    .nav {

        @include sizethree {

            display: none;

        }

    }

    .hero {

        display: block;
        color: white;
        mix-blend-mode: difference;

        @include sizethree {

            mix-blend-mode: normal;

        }

    }

}

.xlarge .hero {

    mix-blend-mode: normal;

}

h1 {

    position: fixed;
    top: calc((((100vw - 40px) / 8) * 2) + 81px - 12px);
    left: 50%;
    transform: translate(-50%, 0%);
    // z-index: -1;
    font-family: 'proto';
    word-spacing: -0.1em;
    width: 100%;
    text-align: center;

    transition: top 0.5s ease,
        opacity 0.5s ease;

    @include sizethree {

        top: calc((((100vw - 18px) / 5) * 2) + 30px - 12px);

    }

    @include sizeone {

        // top: calc(((100vw - 20px) / 2) + 50px - 10px);
        top: 50%;
        transform: translate(-50%, -50%);

        word-spacing: -0.08em;


    }

}

.is-text,
.is-curiculum {

    header {

        background-color: transparent;

        @include sizethree {

            background-color: white;

        }

    }

    .hero {

        display: none;

    }

    h1 {

        display: none;

    }

}

/* ------------------------------------------------------------------ */
/*        FOOTER
/* ------------------------------------------------------------------ */

.footer {

    padding-bottom: 3px;

}

.footer,
footer {

    position: fixed;
    background-color: white;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'compress', sans-serif;

    transition: opacity 0.5s ease 3s,
        bottom 0.5s ease 3s;

    @include sizethree {

        transition: opacity 0.5s ease 1.5s,
            bottom 0.5s ease 1.5s;

    }

    &_nav ul {

        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include sizethree {

            height: 56px;

        }

        li {

            padding: 2px 7px;
            cursor: pointer;
            height: fit-content;

            &:hover {

                font-family: 'proto', sans-serif;
                word-spacing: -0.1em;

                @include sizethree {

                    font-family: 'compress', sans-serif;

                }

            }

            .checker {

                border: 1px solid black;
                border-radius: 10px;
                width: 11px;
                height: 11px;
                display: inline-block;
                margin-right: 6px;
                // pointer-events: none;

                transition: background-color 0.3s ease,
                    border-color 0.3s ease;

            }

            &.download {

                position: relative;
                top: 0px;

            }

            .arrow svg {

                width: 10px;
                position: relative;
                bottom: -3px;
                margin-right: 7px;

            }

            &:hover .checker {

                background-color: black;

            }

            &.selected {

                color: $red;
                font-family: 'proto', sans-serif;
                word-spacing: -0.1em;

                .checker {

                    border-color: $red;
                    background-color: $red;

                }

                @include sizeone {

                    word-spacing: -0.08em;

                }

            }

        }

    }

    .language_nav {

        position: fixed;
        bottom: 0px;
        right: 22px;

        @include sizethree {

            left: 50%;
            transform: translate(-50%, 0%);
            background-color: white;
            width: 100%;

        }

        ul {

            display: flex;

            @include sizethree {

                justify-content: center;

            }

            li {

                padding: 2px 7px;
                cursor: pointer;

                .checker {

                    border: 1px solid black;
                    border-radius: 10px;
                    width: 11px;
                    height: 11px;
                    display: inline-block;
                    margin-right: 6px;
                    pointer-events: none;

                    transition: background-color 0.3s ease,
                        border-color 0.3s ease;

                }

                &.selected {

                    font-family: 'proto', sans-serif;
                    word-spacing: -0.1em;

                    .checker {

                        border-color: black;
                        background-color: black;

                    }

                    @include sizeone {

                        word-spacing: -0.08em;

                    }

                }

            }

        }
    }

}

.is-home {

    .footer_nav ul {

        height: 100%;

    }

}

.is-home .language_nav {

    opacity: 0;
    pointer-events: none;

}

.is-curiculum .footer,
.is-text .footer {

    transition: opacity 0.5s ease,
        bottom 0.5s ease;

}

.preload {

    h1 {

        opacity: 0;

    }

    .footer {

        opacity: 0;
        bottom: -20px;

        @include sizethree {

            bottom: -200px;
            transition: none;

        }

    }

    header {

        opacity: 0;
        top: -20px;

    }

}

.footer.hide {

    opacity: 0;
    bottom: -25px;

    @include sizethree {

        bottom: -200px;
        transition: none;
        // display: none;

    }

}

.is-text .heros,
.is-curiculum .heros {

    display: none;

}

.page_change .footer {

    opacity: 1 !important;

    // transition: opacity 0.5s ease 0.5s,
    //     bottom 0.5s ease 0.5s;

}

/* ------------------------------------------------------------------ */
/*        404
/* ------------------------------------------------------------------ */

.error_page {

    height: calc(100vh - 313px);

    .error {

        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        a {

            text-transform: uppercase;
        }

    }

}

/* ------------------------------------------------------------------ */
/*        SCREANSAVER
/* ------------------------------------------------------------------ */

.screensaver {

    padding: 0px $marge_site;
    z-index: 1000000000;

    position: fixed;
    top: 0px;
    left: 0px;
    background-color: white;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.3s ease;

    &.show {

        opacity: 1;

    }

    .gallery {

        display: flex;
        flex-wrap: wrap;
        width: calc(100vw - 40px);

        transition: opacity 0.3s ease;

        &_item {

            width: calc((100vw - 40px) / 20);
            height: calc((100vw - 40px) / 20);
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'proto';
            word-spacing: -0.1em;


            transition: opacity 0.3s ease,
                height 0.3s ease;


            @include sizeone {

                width: calc((100vw - 40px) / 7);
                height: calc((100vw - 40px) / 7);
                word-spacing: -0.08em;

            }

            &.hide {

                opacity: 0;
                pointer-events: none;

            }

            .image_wrapper {

                height: calc(((100vw - 40px) / 20) - 14px);
                width: calc(((100vw - 40px) / 20) - 14px);
                margin: 10px;
                cursor: zoom-in;

                @include sizeone {

                    height: calc(((100vw - 40px) / 7) - 14px);
                    width: calc(((100vw - 40px) / 7) - 14px);

                }

                img {

                    object-fit: contain;
                    width: calc(((100vw - 40px) / 20) - 14px);
                    height: calc(((100vw - 40px) / 20) - 14px);
                    object-position: left;

                    transition: filter 0.3s ease,
                        width 0.3s ease;

                    @include sizeone {

                        height: calc(((100vw - 40px) / 7) - 14px);
                        width: calc(((100vw - 40px) / 7) - 14px);

                    }

                }

            }

            &.horizontal {

                width: calc(((100vw - 40px) / 20) * 2);
                height: calc((100vw - 40px) / 20);
                justify-content: flex-start;

                @include sizeone {

                    width: calc(((100vw - 40px) / 7) * 2);
                    height: calc((100vw - 40px) / 7);

                }

                .image_wrapper {

                    width: calc((((100vw - 40px) / 20) - 14px) * 2);
                    margin: 7px;

                    @include sizeone {

                        width: calc((((100vw - 40px) / 7) - 14px) * 2);

                    }

                    img {

                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                        object-position: left;

                    }
                }

            }

        }

    }

}