/* ------------------------------------------------------------------ */
/*        FONT FACE DECLARATION
/* ------------------------------------------------------------------ */

@font-face {
    font-family: 'proto';
    src: url(https://olymperacanaweiler.com/assets/fonts/Protocole-Regular.woff2),
        url(https://olymperacanaweiler.com/assets/fonts/Protocole-Regular.woff) format('woff'),
        url(https://olymperacanaweiler.com/assets/fonts/Protocole-Regular.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'proto';
    src: url(https://olymperacanaweiler.com/assets/fonts/Protocole-Italic.woff2),
        url(https://olymperacanaweiler.com/assets/fonts/Protocole-Italic.woff) format('woff'),
        url(https://olymperacanaweiler.com/assets/fonts/Protocole-Italic.otf) format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'compress';
    src: url(https://olymperacanaweiler.com/assets/fonts/ProtocoleCompress-Regular.woff2),
        url(https://olymperacanaweiler.com/assets/fonts/ProtocoleCompress-Regular.woff) format('woff'),
        url(https://olymperacanaweiler.com/assets/fonts/ProtocoleCompress-Regular.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'compress';
    src: url(https://olymperacanaweiler.com/assets/fonts/ProtocoleCompress-Italic.woff2),
        url(https://olymperacanaweiler.com/assets/fonts/ProtocoleCompress-Italic.woff) format('woff'),
        url(https://olymperacanaweiler.com/assets/fonts/ProtocoleCompress-Italic.otf) format('opentype');
    font-weight: 300;
    font-style: italic;
}