/* ------------------------------------------------------------------ */
/*        COMMON PROJECT VARIABLES
/* ------------------------------------------------------------------ */

$marge_site : 13px;

/* ------------------------------------------------------------------ */
/*        BREAKPOINTS
/* ------------------------------------------------------------------ */
$breakpoint-sizezero : 400px;
$breakpoint-sizeone : 800px;
$breakpoint-sizetwo : 1000px;
$breakpoint-sizethree : 1300px;
$breakpoint-sizefour : 1700px;
$breakpoint-sizefive : 1701px;

/* ------------------------------------------------------------------ */
/*        COLORS
/* ------------------------------------------------------------------ */

$white : #111111;
$black : #f8f8f8;
$red : #FF0000;


/* ------------------------------------------------------------------ */
/*        EASE
/* ------------------------------------------------------------------ */

$easeToinou: cubic-bezier(.16, .29, 0, 1);
$Power0EaseNone: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$Power1EaseIn: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$Power1EaseOut: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$Power1EaseInOut: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$Power2EaseIn: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$Power2EaseOut: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$Power2EaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$Power3EaseIn: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$Power3EaseOut: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$Power3EaseInOut: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$Power4EaseIn: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$Power4EaseOut: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$Power4EaseInOut: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$CircEaseIn: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$CircEaseOut: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$CircEaseInOut: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$ExpoEaseIn: cubic-bezier(.95, .05, .795, .035);
$ExpoEaseOut: cubic-bezier(.19, 1, .22, 1);
$ExpoEaseInOut: cubic-bezier(1, 0, 0, 1);
$SineEaseIn: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$SineEaseOut: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$SineEaseInOut: cubic-bezier(0.445, 0.050, 0.550, 0.950);