.curiculum {

    padding-bottom: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-family: 'proto', sans-serif;

    @include sizethree {

        padding-top: 55px;
        margin: 0px 15px;
        padding-bottom: 63px;

    }

    .section {

        padding-top: 4px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

    }

    .titrage {

        text-align: center;
        margin-bottom: 50px;

        .line_two {

            font-family: 'compress', sans-serif;

        }

    }

    .part {

        width: 650px;

        @include sizeone {

            width: 100%;

        }

        .title {

            margin-top: 25px;
            text-align: center;
            text-transform: uppercase;

        }

        .info_line {

            position: relative;

            .content {

                margin-left: 40px;

            }

            .date {

                font-size: 0.75rem;
                letter-spacing: 0.8px;
                position: absolute;
                top: 5px;

            }

        }

        .bio {

            display: none;
            text-indent: 2rem;

            p:first-child {

                text-indent: 0rem;

            }


            p {

                text-align: justify;
                text-justify: auto;
                hyphens: auto;

            }

        }

    }

    a {

        color: $red !important;
        text-decoration: underline !important;

        &:hover {

            text-decoration: none !important;

        }

    }

}

.section[data-id='biographie'] {

    margin-top: 50px;

}

[data-lang='is-fr'] .curiculum .part .bio_fr {

    display: block;

}

[data-lang='is-en'] .curiculum .part .bio_en {

    display: block;

}