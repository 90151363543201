/* ------------------------------------------------------------------ */
/*        HEADING DECLARATION
/* ------------------------------------------------------------------ */



* {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    background-repeat: no-repeat;
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    transform: scaleX(1);
    transition: transform 0.3s ease;

}

html.lock {

    overflow: hidden;

}

html {

    font-size: 1.1rem;
    font-family: 'compress', sans-serif;
    letter-spacing: 0.3px;
    line-height: 1.25;
    font-weight: 100;
    width: 100vw;
    overflow-x: hidden;

    @include sizeone {

        font-size: 1.05rem;

    }

}


body {

    transition: opacity .5s ease;

}

.desk {

    @include sizeone {

        display: none !important;

    }

}

.mob,
.mob-flex {

    display: none !important;

}

.mob {

    @include sizeone {

        display: block !important;

    }
}

.mob-flex {

    display: none !important;

    @include sizeone {

        display: flex !important;

    }

}